import AuthDialog from "@/components/auth-dialog";
import { RouterLink } from "@/components/routing/router-link";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function Landing() {
  return (
    <ScrollArea className="h-[100vh] bg-gradient-to-b from-[#22c55e]">
      <div className="py-12 sm:py-20">
        <div className="container px-4 flex flex-col gap-8">
          <header className="flex flex-col items-center gap-2">
            <div className="text-center">
              <h1 className="text-3xl font-bold tracking-tight">Pay4Delay</h1>
              <h2 className="my-2 font-bold tracking-tight">
                Odszkodowania za opóźnienia pociągów
              </h2>
              <p className="mt-2">
                Zgłoś opóźnienie pociągu i odbierz należne Ci odszkodowanie.
              </p>
            </div>
          </header>
          <div className="flex justify-center">
            <AuthDialog />
          </div>
        </div>
      </div>
      <div className="border-t border-b border-gray-200 dark:border-gray-800">
        <div className="container px-4 grid gap-12 py-12 sm:py-20">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">
                Bezproblemowe Odszkodowanie
              </h2>
              <p className="max-w-[600px]">
                Prześlij bilet oraz informacje o opóźnieniu pociągu i otrzymaj
                odszkodowanie bez stresu.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">
                Natychmiastowe Powiadomienia
              </h2>
              <p className="max-w-[600px]">
                Otrzymuj powiadomienia o stanie twojego zgłoszenia.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">
                Apka przyjazna dla użytkownika
              </h2>
              <p className="max-w-[600px">
                Intuicyjny interfejs zaprojektowany, aby maksymalnie uprościć
                cały proces.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 sm:py-20">
        <div className="container px-4">
          <div className="grid gap-8 lg:grid-cols-2 items-center">
            <div className="flex flex-col gap-4">
              <h2 className="text-3xl font-bold tracking-tight">
                `Pociągi są często opóźnione, a ja łatwo mogę ubiegać się o
                odszkodowanie. Ta aplikacja zaoszczędziła mi wiele pieniędzy i
                wysiłku.`
              </h2>
              <p className="">- Krzysiek, 32 lata</p>
            </div>
            <div className="flex flex-col items-center gap-4">
              <img
                alt="Ilustracja pociągu"
                className="aspect-[3/2] overflow-hidden rounded-lg object-cover"
                height="400"
                src="/logo.png"
                width="600"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-8 border-t border-b border-gray-200 dark:border-gray-800">
        <div className="container px-4 py-12 sm:py-20">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">
                Odszkodowanie za opóźnienia pociągów w zasięgu ręki
              </h2>
              <p className="max-w-[600px]">
                Prześlij informacje o opóźnieniu pociągu i otrzymaj
                odszkodowanie bez stresu.
              </p>
            </div>
            <AuthDialog view="sign_up" />
          </div>
          <div className="flex flex-col items-center gap-4 text-center my-8">
            <RouterLink route={{ path: "privacy", title: "Prywatność" }}>
              Polityka prywatności
            </RouterLink>
            <RouterLink route={{ path: "terms", title: "Regulamin" }}>
              Regulamin
            </RouterLink>
            <RouterLink route={{ path: "contact", title: "Kontakt" }}>
              Kontakt
            </RouterLink>
            <RouterLink route={{ path: "faq", title: "FAQ" }}>FAQ</RouterLink>
          </div>
        </div>
      </div>
    </ScrollArea>
  );
}
