import ModeToggle from "@/components/mode-toggle";
import { useTheme } from "@/lib/theme/theme-provider";

export default function Theme() {
  const { theme } = useTheme();

  return (
    <>
      <p className="w-full mb-2">Twój aktualny motyw graficzny.</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 fade-in">
        <div className="space-y-2">
          <ModeToggle />
          {theme === "light"
            ? "Jasny"
            : theme === "dark"
            ? "Ciemny"
            : "Systemowy"}
        </div>
      </div>
    </>
  );
}
