import { useEffect, useState } from "react";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { downloadSupabaseFile } from "@/lib/supabase/download-file";
import { base64ToFile } from "@/utils/base64";

type PDFPreviewProps = {
  ctaLabel: string;
  pdfUrl?: string | null;
  ticketType?: "img" | "pdf";
  bucket?: "tickets" | "compensations";
  blob?: string;
};

export function PDFPreview(props: PDFPreviewProps) {
  const [file, setFile] = useState<string | null>(null);

  const handleClick = () =>
    !file && props.pdfUrl
      ? downloadSupabaseFile(
          props.bucket || "tickets",
          props.pdfUrl,
          false
        ).then(({ url }) => {
          setFile(url);
        })
      : {};

  useEffect(() => {
    async function makeFileFromBlob() {
      if (file || !props.blob) {
        return;
      }
      const f = await base64ToFile(props.blob, "ticket.pdf");
      setFile(URL.createObjectURL(f));
    }
    makeFileFromBlob();
  }, [file, props.blob]);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          size="sm"
          onClick={handleClick}
          disabled={!props.pdfUrl && !props.blob}
        >
          <AlertDialogTitle>{props.ctaLabel}</AlertDialogTitle>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="w-full min-w-fit overflow-scroll">
        {file ? (
          <iframe
            src={file}
            width={800}
            height={600}
            style={{ width: "100%", maxHeight: "90vh" }}
          />
        ) : (
          <div className="animate-pulse">Trwa ładowanie...</div>
        )}
        <AlertDialogFooter>
          <AlertDialogCancel>Zamknij</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
