import { GenericInputField } from "../types";
import { WriteItem } from "./types";

export const fields: Record<keyof WriteItem, GenericInputField> = {
  id: {
    input: "text",
    label: "ID zgłoszenia",
    readonly: true,
  },
  created_at: {
    input: "date",
    label: "Data utworzenia",
  },
  updated_at: {
    input: "date",
    label: "Data aktualizacji",
  },
  delay_1h_or_more: {
    input: "boolean",
    label: "Opóźnienie co najmniej 1h",
  },
  processing_fee: {
    input: "currency",
    label: "Opłata za przetworzenie",
  },
  status: {
    input: "text",
    label: "Status",
  },
  requested_refund: {
    input: "currency",
    label: "Żądany zwrot",
  },
  received_refund: {
    input: "currency",
    label: "Otrzymany zwrot",
  },
  additional_details: {
    input: "text",
    label: "Dodatkowe szczegóły",
  },
  link_to_ticket: {
    input: "url",
    label: "Link do przesłanego biletu",
  },
  link_to_generated_document: {
    input: "url",
    label: "Link do wygenerowanego dokumentu",
  },
  user_id: {
    input: "text",
    label: "ID użytkownika",
  },
  journey_details__arrival_time: {
    input: "text",
    label: "Czas przyjazdu",
  },
  journey_details__carrier: {
    input: "text",
    label: "Przewoźnik",
  },
  journey_details__date: {
    input: "text",
    label: "Data podróży",
  },
  journey_details__departure_time: {
    input: "text",
    label: "Czas odjazdu",
  },
  journey_details__route: {
    input: "text",
    label: "Trasa",
  },
  journey_details__train_number: {
    input: "text",
    label: "Numer pociągu",
  },
  ticket_details__passenger_full_name: {
    input: "text",
    label: "Imię i nazwisko pasażera",
  },
  ticket_details__currency: {
    input: "text",
    label: "Waluta",
  },
  ticket_details__ticket_number: {
    input: "text",
    label: "Numer biletu",
  },
  ticket_details__total_amount: {
    input: "text",
    label: "Całkowita kwota",
  },
  ticket_file_type: {
    input: "text",
    label: "Typ pliku biletu",
  },
  temp_document_blob: {
    input: "file",
    label: "Podpisany dokument",
    readonly: true,
  },
};

export const userReadableCompensationFieldsString = Object.keys(fields).join(
  ", ",
) as "id, created_at, updated_at, delay_1h_or_more, processing_fee, status, requested_refund, received_refund, additional_details, link_to_ticket, link_to_generated_document, user_id, journey_details__arrival_time, journey_details__carrier, journey_details__date, journey_details__departure_time, journey_details__route, journey_details__train_number, ticket_details__passenger_full_name, ticket_details__currency, ticket_details__ticket_number, ticket_details__total_amount, ticket_file_type, temp_document_blob";
