import { ActiveUser } from "./types";
import { useReadLoggedUserProfile } from "../supabase/crud-helpers/user";
import { calculateIssues } from "./calculate-issues";

export const useReadActiveUser = () => {
  const { user, profile } = useReadLoggedUserProfile();

  return {
    issues: profile ? calculateIssues(profile) : [],
    user,
    profile: profile,
    allowInteractions: user && profile
      ? user?.role === "authenticated" &&
        profile?.flagged === false &&
        (["create", "update", "delete"].includes(
          String(profile?.crud_rights),
        )) &&
        profile.offences_allowed > profile.offences_committed
      : undefined,
  } as ActiveUser;
};
