import { Button } from "@/components/ui/button";

export const SkeletonTicket = () => {
  return (
    <div className="animate-pulse space-y-4 rounded-lg w-full border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-950">
      <div className="mb-2 flex items-center justify-between space-x-4">
        {Array(2)
          .fill(0)
          .map((_item, i) => (
            <div
              key={i}
              className="h-3 flex-1 bg-gray-300 dark:bg-gray-800 rounded col-span-2"
            />
          ))}
      </div>
      <div className="h-4 flex-1 bg-gray-300 dark:bg-gray-800 rounded col-span-2" />
      <div className="flex-col space-y-4">
        {Array(2)
          .fill(0)
          .map((_item, i) => (
            <Button key={i} size="sm" className="w-full" disabled={true}>
              <p />
            </Button>
          ))}
        <div className="h-4 flex-1 bg-gray-300 dark:bg-gray-800 rounded col-span-2" />
      </div>
    </div>
  );
};
