
const RandomWidthFieldTitle = () => {
    const items = ["max-w-[30%]", "max-w-[55%]", "max-w-[65%]"];
    const item = items[Math.floor(Math.random()*items.length)];
    return (
    <div className={`${item} h-4 bg-gray-200 dark:bg-gray-700 rounded mb-2`}></div>
    )
};

export default function SkeletonProfile() {
    return (
        <div className="animate-pulse">
            <div className="h-6 max-w-[40%] bg-gray-200 dark:bg-gray-700 rounded col-span-2 mb-4"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {Array(8).fill(0).map((_item, i) =>
                    <div key={i}>
                    <div className="y-2">
                        <RandomWidthFieldTitle/>
                         <div className="h-7 flex-1 bg-gray-100 dark:bg-gray-600 rounded col-span-1 mb-2"></div>
                    </div>
                    </div>
            )}
            </div>
        </div>
    )
}