import {
  CompensationStatus,
  getBaseCardStatusColor,
  statusLabels,
} from "@/lib/compensations/types";

type NewsCardProps = {
  redirectTo: "settings/profile" | "compensations";
  id: string;
  title: string;
  subtitle: string;
  status?: CompensationStatus;
  details?: string;
  requiredAction?: string;
};

const actionsForStatus = {
  draft: "Wymaga sprawdzenia",
  approved: "Wymaga podpisu",
  signed: "Wymaga płatności",
  payment_pending: "Oczekuje akceptacji płatności",
  payment_completed: "Opłacony. Wymaga wysłania",
  payment_error: "Błąd płatności. Wymaga ponownej płatności",
  sent: "Wysłany. Oczekuje na decyzję",
  accepted: "Zwrot zaakceptowany",
  rejected: "Zwrot odrzucony",
};

export const NewsCard = (props: NewsCardProps) => (
  <a
    href={`/${props.redirectTo}?id=${props.id}`}
    className="flex items-center justify-between"
  >
    <div>
      <h3 className="text-sm font-medium">{props.title}</h3>
      <i className="text-gray-500 text-sm">{props.subtitle}</i>
      {props?.status && actionsForStatus[props?.status] ? (
        <div className="text-gray-500 text-sm mt-2 flex underline">
          {actionsForStatus[props?.status]}
        </div>
      ) : null}
      {props?.details ? (
        <p className="text-sm mt-2 flex text-red-800">{props?.details}</p>
      ) : null}
    </div>
    {props?.status ? (
      <div
        className={`${getBaseCardStatusColor(
          props.status
        )} px-3 py-1 rounded-full text-xs font-medium`}
      >
        {statusLabels[props.status] || props.status}
      </div>
    ) : null}
  </a>
);
