import ImageInputToggle from "../img-camera";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { Switch } from "../ui/switch";

export default function MinimalCompensationFormContent() {
  return (
    <>
      <div className="flex items-center justify-between">
        <Label htmlFor="delay_in_minutes">Opóźnienie powyżej 59 minut</Label>
        <Switch
          id="delay_1h_or_more"
          name="delay_1h_or_more"
          className="text-right"
        />
      </div>
      <div className="space-y-1">
        <Label htmlFor="file">
          <ImageInputToggle />
        </Label>
      </div>
      <br />
      <div className="flex items-center space-x-2">
        <Checkbox id="prepare" checked disabled />
        <label
          htmlFor="prepare"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Automatycznie przygotuj szkic zgłoszenia
        </label>
      </div>
    </>
  );
}
