import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useReadActiveUser } from "@/lib/user/read";
import { useUpdateActiveUser } from "@/lib/user/update";

import { languageFields } from "./fields";

export default function Locale() {
  const { profile: data } = useReadActiveUser();
  const { handleUpdateSubmit } = useUpdateActiveUser();

  return (
    <>
      <p className="w-full mb-2">Twój język aplikacji.</p>
      {data ? (
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-6 fade-in"
          onSubmit={handleUpdateSubmit(Object.keys(languageFields))}
        >
          {Object.entries(languageFields).map(([field]) => (
            <div className="space-y-2" key={field}>
              <Label htmlFor={field}>Aktualny wybór</Label>
              <Input
                id={field}
                name={field}
                placeholder="Wybierz język aplikacji"
                defaultValue={String(data?.[field as keyof typeof data])}
                disabled
              />
            </div>
          ))}
          <div className="flex space-x-4 mt-4">
            <Button type="reset" variant="outline">
              Anuluj
            </Button>
            <Button type="submit">Zapisz</Button>
          </div>
        </form>
      ) : (
        "Trwa ładowanie danych..."
      )}
    </>
  );
}
