import { useReadItems } from "@/lib/compensations/read";
import NewsSkeleton from "@/components/ui/skeletons/news-skeleton";
import { NewsCard } from "@/components/news-card";
import { RequestedRefundsChart } from "@/components/news-chart";
import { CompensationStatus } from "@/lib/compensations/types";
import { useReadActiveUser } from "@/lib/user/read";

export default function News() {
  const { data: compensations } = useReadItems();
  const { issues } = useReadActiveUser();

  const lastCompensation = compensations?.[0];

  return compensations ? (
    <section className="h-[calc(100%_-_6rem)] w-[90%] p-2 fade-in overflow-scroll">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className=" rounded-lg shadow-md p-6">
          <h2 className="text-lg font-bold mb-4">Wymaga uwagi</h2>
          <div className="space-y-4">
            {issues.length ? (
              <>
                <h4 className="text-sm font-bold mb-4 italic">Profil</h4>
                {issues.map(({ field, issue, fieldLabel, fieldValue }) => (
                  <NewsCard
                    redirectTo="settings/profile"
                    id={field}
                    key={field}
                    title={String(fieldLabel)}
                    subtitle={issue}
                    status={"Problem!" as CompensationStatus}
                    details={"Jest: " + fieldValue}
                  />
                ))}
              </>
            ) : null}
            {compensations.length ? (
              <>
                <h4 className="text-sm font-bold mb-4 italic">Zgłoszenia</h4>
                {compensations
                  ?.filter(
                    (c) =>
                      ["sent", "accepted", "rejected"].includes(c.status) ===
                      false
                  )
                  .map((compensation) => (
                    <NewsCard
                      redirectTo="compensations"
                      id={compensation.id}
                      key={compensation.id}
                      title={
                        compensation?.journey_details__route ||
                        "Brak informacji"
                      }
                      subtitle={
                        compensation?.journey_details__date +
                        ", " +
                        compensation?.journey_details__carrier +
                        ", " +
                        (compensation?.delay_1h_or_more
                          ? "Godzina lub więcej opóźnienia"
                          : "Mniej niż godzina opóźnienia")
                      }
                      status={compensation.status}
                      details={
                        lastCompensation?.additional_details || "Brak uwag"
                      }
                    />
                  ))}
              </>
            ) : null}
          </div>
        </div>
        <div className=" rounded-lg shadow-md p-6">
          <h2 className="text-lg font-bold mb-4">Ostatnie zgłoszenie</h2>
          <div className="space-y-4">
            {lastCompensation ? (
              <NewsCard
                redirectTo="compensations"
                id={lastCompensation?.id ?? ""}
                title={
                  lastCompensation?.journey_details__route || "Brak informacji"
                }
                subtitle={
                  lastCompensation?.journey_details__date +
                  ", " +
                  lastCompensation?.journey_details__carrier +
                  ", " +
                  (lastCompensation?.delay_1h_or_more
                    ? "Godzina lub więcej opóźnienia"
                    : "Mniej niż godzina opóźnienia")
                }
                status={lastCompensation?.status || "draft"}
                details={lastCompensation?.additional_details || "Brak uwag"}
              />
            ) : (
              "Brak"
            )}
          </div>
        </div>
      </div>
      <div className=" rounded-lg shadow-md p-6 mt-6">
        <h2 className="text-lg font-bold mb-4">Zgłoszone kwoty odszkodowań</h2>
        <RequestedRefundsChart
          className="aspect-[16/9]"
          compensations={compensations}
        />
      </div>
    </section>
  ) : (
    <NewsSkeleton />
  );
}
