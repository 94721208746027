import React from "react";
import { LogOut, Menu, Telescope } from "lucide-react";

import { drawerMenuLinks } from "@/components/routing/routes";

import { signOut, useAuth } from "../../../lib/auth/auth-provider";
import { RouterLink } from "../../routing/router-link";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Button } from "../../ui/button";
import { ScrollArea } from "../../ui/scroll-area";
import { Separator } from "../../ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../ui/sheet";

export default function HeaderMenu() {
  const { session } = useAuth();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon">
          <Menu />
        </Button>
      </SheetTrigger>
      <SheetContent side={"left"} className="">
        <SheetHeader>
          <SheetTitle className="flex items-center gap-2">
            Witaj, {session?.user?.user_metadata.name || "Nieznajomy"}{" "}
            <Avatar>
              <AvatarImage src={session?.user?.user_metadata.avatar_url} />
              <AvatarFallback>??</AvatarFallback>
            </Avatar>
          </SheetTitle>

          <SheetDescription>
            {new Date().toLocaleString("pl", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
            <br />
          </SheetDescription>
        </SheetHeader>
        <Separator className="my-4" />
        <ScrollArea className="h-[calc(100%_-_12rem)]">
          <div className="grid gap-6 py-6">
            {drawerMenuLinks.map((item, i) => (
              <React.Fragment key={i}>
                {item.map((route) => (
                  <RouterLink key={route.path} route={route}>
                    <SheetClose asChild>
                      <div className="flex gap-2 items-center">
                        {route.icon && <route.icon className="mr-2 h-4 w-4" />}
                        <div>{route.title}</div>
                      </div>
                    </SheetClose>
                  </RouterLink>
                ))}
                {i + 1 === drawerMenuLinks.length ? null : <Separator />}
              </React.Fragment>
            ))}
            <a
              href="https://insigh.to/b/pay4delay"
              target="_blank"
              rel="noreferrer"
            >
              <SheetClose asChild>
                <div className="flex gap-2 items-center">
                  <Telescope className="mr-2 h-4 w-4" />
                  <div>Zaproponuj funkcjonalność</div>
                </div>
              </SheetClose>
            </a>
          </div>
        </ScrollArea>
        <Separator className="my-4" />
        <SheetFooter className="">
          <SheetClose asChild>
            <Button type="submit" onClick={signOut}>
              <LogOut className="mr-2 h-4 w-4" />
              Wyloguj się
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
