import {
  Book,
  BugIcon,
  Contact,
  CreditCard,
  HandshakeIcon,
  Home,
  Keyboard,
  LifeBuoy,
  Mail,
  MessagesSquareIcon,
  NewspaperIcon,
  Settings,
  Share,
  ShieldQuestion,
  Telescope,
  User,
} from "lucide-react";

import ArchivedCompensations from "@/routes/protected/archived-compensations";
import Compensations from "@/routes/protected/compensations";
import Finalized from "@/routes/protected/finalized";
import HelpPage from "@/routes/protected/help";
import InvitePage from "@/routes/protected/invite";
import News from "@/routes/protected/news";
import SettingsPage from "@/routes/protected/settings";
import DeleteAccount from "@/routes/protected/settings/delete";
import Locale from "@/routes/protected/settings/locale";
import Marketing from "@/routes/protected/settings/marketing";
import Notifications from "@/routes/protected/settings/notifications";
import Profile from "@/routes/protected/settings/profile";
import Theme from "@/routes/protected/settings/theme";
import TechPage from "@/routes/protected/tech";
import Main from "@/routes/protected/main";

export const settingsMenuLinks = [
  {
    path: "profile",
    title: "Zarządzaj profilem",
    subtitle: "Konto użytkownika PAY4DELAY",
    icon: User,
    element: Profile,
  },
  {
    path: "notifications",
    title: "Powiadomienia",
    subtitle: "Zarządzaj powiadomieniami",
    icon: Mail,
    element: Notifications,
  },
  {
    path: "marketing",
    title: "Zarządzaj zgodami",
    subtitle: "Zgody marketingowe",
    icon: CreditCard,
    element: Marketing,
  },
  {
    path: "theme",
    title: "Motyw aplikacji",
    subtitle: "Zmień wygląd aplikacji",
    icon: Telescope,
    element: Theme,
  },
  {
    path: "language",
    title: "Język aplikacji",
    subtitle: "Domyślny (polski)",
    icon: Book,
    element: Locale,
  },
  {
    path: "account",
    title: "Usuń konto",
    subtitle: "Zarządzaj kontem",
    icon: Contact,
    element: DeleteAccount,
  },
];

export const footerLinks = [
  {
    path: "news",
    title: "Aktualności",
    icon: NewspaperIcon,
    element: News,
  },
  {
    path: "compensations",
    title: "Aktualne zgłoszenia",
    icon: MessagesSquareIcon,
    element: Compensations,
  },
  {
    path: "stats",
    title: "Zakończone zgłoszenia",
    icon: HandshakeIcon,
    element: Finalized,
  },
];

export const drawerMenuLinks = [
  [
    {
      path: "archive",
      title: "Archiwum zgłoszeń",
      icon: Keyboard,
      element: ArchivedCompensations,
    },
    {
      path: "settings",
      title: "Ustawienia",
      icon: Settings,
      element: SettingsPage,
      children: settingsMenuLinks,
    },
    {
      path: "help",
      title: "Pomoc",
      icon: LifeBuoy,
      element: HelpPage,
    },
    {
      path: "about",
      title: "O aplikacji",
      icon: Telescope,
      element: TechPage,
    },
    {
      path: "invite",
      title: "Zaproś do aplikacji",
      icon: Share,
      element: InvitePage,
    },
  ],
];

export const unlinkableRoutes = [
  {
    path: "error",
    title: "Błąd",
    icon: BugIcon,
    element: () => "Wystąpił błąd",
  },
  {
    path: "/",
    title: "Strona główna",
    icon: Home,
    element: Main,
  },
  {
    path: "*",
    title: "Nie znaleziono",
    icon: ShieldQuestion,
    element: () => "Nie znaleziono strony o podanym adresie",
  },
];

export const menuLinks = [...drawerMenuLinks.flat(), ...footerLinks];
