import {
  useDeleteMutation,
  useInsertMutation,
  useQuery,
  useUpdateMutation,
} from "@supabase-cache-helpers/postgrest-swr";
import { supabase } from "../supabase";
import { useReadLoggedUserProfile } from "./user";
import { Database } from "../schema";
import { logger } from "@/lib/logger";
import { userReadableCompensationFieldsString } from "@/lib/compensations/fields";

export const useCreateCompensation = () => {
  const { user } = useReadLoggedUserProfile();

  const { trigger, error } = useInsertMutation(
    //@ts-expect-error - library out of date, need to investigate options
    supabase
      .from("compensations"),
    ["id"],
    "id",
  );

  if (error) {
    logger.error("useCreateCompensation", { user, error });
  }

  return {
    error,
    insertCompensation: (
      compensation: Omit<
        Database["public"]["Tables"]["compensations"]["Row"],
        "user_id"
      >,
    ) => trigger([{ user_id: user?.id || "anon", ...compensation }]),
  };
};

export function useReadCompensations() {
  const { user } = useReadLoggedUserProfile();

  const { data, error } = useQuery(
    user?.id
      ? supabase
        .from("compensations")
        .select(userReadableCompensationFieldsString)
        .eq("user_id", user?.id || "user unknown")
        .order("created_at", { ascending: false })
      : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (error) {
    logger.error("useReadCompensations", { user, error });
  }

  return { data, error };
}

export function useUpdateCompensation() {
  const { trigger, error } = useUpdateMutation(
    // @ts-expect-error - library out of date, need to investigate options
    supabase.from("compensations"),
    ["id"],
    userReadableCompensationFieldsString,
  );

  if (error) {
    logger.error("useUpdateCompensation", { error });
  }

  return {
    error,
    updateCompensation: trigger,
  };
}

export function useDeleteCompensation() {
  const { trigger, error } = useDeleteMutation(
    // @ts-expect-error - library out of date, need to investigate options
    supabase
      .from("compensations"),
    ["id"],
    "id",
  );

  if (error) {
    logger.error("useDeleteCompensation", { error });
  }

  return {
    error,
    deleteCompensationById: (id: string) => trigger({ id }),
  };
}
