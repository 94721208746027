import { supabase } from "./supabase";

export async function uploadFile(
  bucketName: string,
  uploadName: string,
  file: File,
) {
  const { data: upload, error: uploadError } = await supabase.storage
    .from(bucketName)
    .upload(uploadName, file, {
      contentType: file?.type,
      cacheControl: "immutable",
      upsert: false,
    });

  return { upload, uploadError };
}
