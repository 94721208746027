import { Outlet, useLocation } from "react-router-dom";

import { menuLinks, settingsMenuLinks } from "@/components/routing/routes";

import { Toaster } from "../ui/toaster";

export default function Main() {
  const location = useLocation();

  const title =
    settingsMenuLinks.find((link) =>
      location.pathname.split("/")?.[2]?.includes(link.path)
    )?.title ||
    menuLinks.find((link) => "/" + link.path === location.pathname)?.title;

  return (
    <>
      <main className="flex flex-col items-center w-full h-full my-16">
        <h1 className="text-xl font-bold m-4">{title}</h1>
        <Outlet />
      </main>
      <Toaster />
    </>
  );
}
