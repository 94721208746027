import { useState, useEffect, useRef } from "react";
import Signature, { PointGroup } from "signature_pad";
import { Undo, Redo, Eraser } from "lucide-react";

function findParentElementSizes(element: HTMLElement | null): {
  width: number;
  height: number;
} {
  if (!element) return { width: 0, height: 0 };
  const size = element.parentElement?.getBoundingClientRect();
  return {
    width: size?.width || 0,
    height: (size?.height || 0) > 100 ? 100 : size?.height || 100,
  };
}

type SignaturePadProps = {
  setIsSigned?: (isSigned: boolean) => void;
};

export default function SignaturePad(props: SignaturePadProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [signaturePad, setSignaturePad] = useState<Signature>();
  // const [savedSignature, setSavedSignature] = useState("");

  const signatureRedoArray: PointGroup[] = [];

  const readyPad = () => {
    const wrapper = document.getElementById("signature-pad");
    const canvas = wrapper?.querySelector("canvas");
    if (!canvas) return;
    canvas.getContext("2d")?.scale(1, 1);
    canvas.height = 150;
    const tempSignaturePad = new Signature(canvas, {
      backgroundColor: "rgb(255, 255, 255)",
    });
    setSignaturePad(tempSignaturePad);
  };

  // const handleSave = () => {
  //   setSavedSignature(signaturePad.toDataURL());
  // };

  const handleUndo = () => {
    let signatureRemovedData = [] as unknown as PointGroup;
    if (!signaturePad) return;
    const signatureData = signaturePad.toData();
    const signatureRedoData = structuredClone(signatureData); //original data

    if (signatureData.length > 0) {
      signatureData.pop(); // remove the last dot or line
      signaturePad.fromData(signatureData);
      signatureRemovedData = signatureRedoData[signatureRedoData.length - 1];
      signatureRedoArray.push(signatureRemovedData);
    }
  };

  const handleRedo = () => {
    if (signatureRedoArray.length !== 0) {
      const values = signaturePad?.toData();
      const lastValue = signatureRedoArray[signatureRedoArray.length - 1];
      values?.push(lastValue);
      if (!signaturePad || !values) return;
      signaturePad?.fromData(values);
      signatureRedoArray.pop(); //remove the redo item from array
    }
  };

  const handleClear = () => {
    signaturePad?.clear();
  };

  useEffect(() => {
    readyPad();
    setCanvasSize(findParentElementSizes(canvasRef.current));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const isEmpty = signaturePad?.isEmpty();
      console.log("isEmpty", isEmpty);
      if (props.setIsSigned) {
        props.setIsSigned(!isEmpty);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [signaturePad, props]);

  return (
    <div id="signature-pad">
      <canvas
        id="signature-canvas"
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        style={{
          border: "1px solid #000",
        }}
      />
      <p className="my-2 flex gap-4 text-gray-400" id="signature-hint">
        (Podpisz się w przestrzeni powyzej)
      </p>
      <div className="flex gap-4 text-gray-400" id="signature-controls">
        {/* <button onClick={handleSave}>
          <FaSave />
        </button> */}
        <button onClick={handleUndo}>
          <Undo />
        </button>
        <button onClick={handleRedo}>
          <Redo />
        </button>
        <button onClick={handleClear}>
          <Eraser />
        </button>
      </div>
    </div>
  );
}
