import { fields as userFields } from "@/lib/user/fields";

const {
  full_name,
  username,
  email,
  phone,
  zip,
  city,
  address_line_1,
  address_line_2,
  province,
  country,
  iban,
} = userFields;

export const profileFields = {
  full_name,
  username,
  email,
  phone,
  zip,
  city,
  address_line_1,
  address_line_2,
  province,
  country,
  iban,
};
