import {
  useQuery,
  useUpdateMutation,
} from "@supabase-cache-helpers/postgrest-swr";
import { User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { userReadableProfileFieldsString } from "../../user/fields";
import { getActiveSession } from "../session";
import { supabase } from "../supabase";
import { logger } from "@/lib/logger";
import { Database } from "../schema";

export const useReadLoggedUserProfile = () => {
  const [user, setUser] = useState<User>();
  const { data, error } = useQuery(
    user?.id
      ? supabase
        .from("profiles")
        .select(userReadableProfileFieldsString)
        .eq("id", user?.id || "user unknown")
      : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  useEffect(() => {
    getActiveSession().then((response) => setUser(response));
  }, []);

  if (error) {
    logger.error("useReadLoggedUserProfile", { user, data, error });
    throw new Error("Nie przygotować odczytu profilu.");
  }

  return {
    user,
    profile: data?.[0] as Database["public"]["Tables"]["profiles"]["Row"],
  };
};

export const useUpdateUserProfile = () => {
  const { user } = useReadLoggedUserProfile();
  const { trigger, error } = useUpdateMutation(
    // @ts-expect-error - library out of date, need to investigate options
    supabase.from("profiles"),
    ["id"],
    userReadableProfileFieldsString,
  );

  if (error) {
    logger.error("useUpdateUserProfile", { error });
  }

  return {
    error,
    updateProfile: (
      update: Partial<
        Omit<Database["public"]["Tables"]["profiles"]["Row"], "id">
      >,
    ) => trigger({ id: user?.id, ...update }),
  };
};
