import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import * as Sentry from "@sentry/react";
import { SeverityLevel } from "@sentry/react";
import { SupabaseClient } from "@supabase/supabase-js";
import { Metrics } from "@sentry/types";
import { projectURL } from "../supabase/supabase";

export function enableSentryLogging() {
  Sentry.init({
    enabled: window.location.hostname.includes("localhost") ? false : true,
    debug: false,
    environment: window.location.hostname.includes("beta")
      ? "development"
      : "production",
    dsn:
      "https://0f2c763fa23d1b1a22c8fed04b652b2f@o4507485980917760.ingest.de.sentry.io/4507485983670352",
    integrations: [
      Sentry.browserTracingIntegration({
        shouldCreateSpanForRequest: (url) => {
          return !url.startsWith(`${projectURL}/rest`);
        },
      }),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserProfilingIntegration(),
      // Sentry.captureConsoleIntegration(),
      supabaseIntegration(SupabaseClient, Sentry, {
        tracing: true,
        breadcrumbs: true,
        errors: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/pay4delay.netlify.app/,
      "pay4delay.app",
      "beta.pay4delay.app",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    profilesSampleRate: 1.0, // Capture 100% of the profiles
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          title: "Przepraszamy, wystąpił problem!",
          subtitle: "Nasz zespół zostanie powiadomiony.",
          subtitle2:
            "Jeśli chcesz, możesz opisać, co się działo, gdy wystąpił ten błąd.",
          labelComments: "Co się stało, gdy wystąpił ten błąd?",
          labelName: "Nazwa użytkownika",
          labelEmail: "Adres e-mail",
          labelClose: "Zamknij",
          labelSubmit: "Wyślij raport błędu",
          errorFormEntry: "Proszę wypełnić wszystkie pola.",
          errorGeneric: "Wystąpił błąd podczas wysyłania raportu.",
          colorScheme: "system",
        });
      }
      return event;
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logSentryError = (exception: any, data?: any) => {
  Sentry.captureException(exception, { data, level: data ? "fatal" : "error" });
};

export const logSentryMessage = (
  message: string,
  extra: { [x: string]: unknown },
  level: SeverityLevel,
) => {
  Sentry.captureMessage(message, {
    extra,
    level,
  });
};

export const logSentryMetric = (
  name: string,
  type = "increment" as keyof Metrics,
) => {
  if (type === "increment") {
    Sentry.metrics[type](name, 1);
  } else throw new Error("Unknown metric type");
};
