import { LogOut } from "lucide-react";
import { Button } from "../ui/button";
import { signOut } from "@/lib/auth/auth-provider";

export const NotAllowed = () => (
  <div className="flex h-full flex-col m-24 text-center">
    <p className="text-current font-bold text-lg">
      Nie masz uprawnień do korzystania z aplikacji. <br />
      Skontaktuj się z{" "}
      <a
        href="mailto:hello@pay4delay.app?subject=PAY4DELAY: Prośba o dostęp"
        className="underline"
      >
        administratorem
      </a>{" "}
      w celu uzyskania pomocy.
    </p>
    <br />
    <Button type="submit" onClick={signOut}>
      <LogOut className="mr-2 h-4 w-4" />
      Wyloguj się
    </Button>
  </div>
);
