import { Button } from "../ui/button";

const reload = () => {
  window.location.reload();
};

export const ErrorPage = () => (
  <div className="flex h-full flex-col m-24">
    <p className="text-primary font-bold text-lg my-4">
      Wystąpił błąd podczas ładowania strony. <br />
    </p>
    <Button type="submit" onClick={reload}>
      Odśwież aplikację
    </Button>
  </div>
);
