import { OpenAI } from "openai";

import { extractTicketDatafunction, systemPrompt } from "./prompts";

import { ReadItem } from "../compensations/types";
import { handleReadTicketResponse } from "./handle-read-ticket";
import { OPEN_AI_MODEL } from "./model";

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

// TODO: move this to a Deno serverless function
export async function readPdfTicket(pdf: File): Promise<ReadItem> {
  const client = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true,
  });

  const file = await client.files.create({
    file: pdf,
    purpose: "assistants",
  });

  const vectorStore = await client.beta.vectorStores.create({
    name: "Ticket Vector Store",
    file_ids: [file.id],
  });

  const assistant = await client.beta.assistants.create({
    name: "Ticket Assistant",
    model: OPEN_AI_MODEL,
    instructions: systemPrompt,
    tool_resources: {
      file_search: {
        vector_store_ids: [vectorStore.id],
      },
    },
    tools: [
      {
        type: "file_search",
      },
      {
        type: "function",
        function: extractTicketDatafunction,
      },
    ],
  });

  const thread = await client.beta.threads.create();
  // await client.beta.threads.messages.create(thread.id, {
  //   role: "user",
  // });

  const run = await client.beta.threads.runs.create(thread.id, {
    assistant_id: assistant.id,
  });

  // 60 retries max, which shouldn't take much more than 1 minute
  for (let i = 0; i < 60; i++) {
    console.log(`Waiting for response... (${i} seconds)`);

    // get the latest run state
    const result = await client.beta.threads.runs.retrieve(thread.id, run.id);

    console.log("Result", result);

    if (result.status === "requires_action") {
      // run has executed
      // parse structured response from the tool call
      const structuredResponse = JSON.parse(
        // @ts-expect-error - we know the response is a string
        result.required_action.submit_tool_outputs.tool_calls[0].function
          .arguments,
      );
      return handleReadTicketResponse(structuredResponse);
    }

    // wait 1 second before retry
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  return {
    is_ticket: false,
  };
}
