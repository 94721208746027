import { footerLinks } from "@/components/routing/routes";

import { RouterLink } from "../routing/router-link";

export default function Footer() {
  return (
    <footer className="fixed h-24 border-t bottom-4 left-0 mx-auto w-full py-2">
      <div className="gap-8 flex flex-row w-full justify-evenly px-4">
        {footerLinks.map((route) => (
          <RouterLink
            className="inline-flex flex-col items-center py-4 text-sm font-medium leading-none h-auto gap-1 text-center"
            route={route}
            key={route.path}
          >
            {route.icon && <route.icon className="h-6 w-6 my-2" />}
            {route.title}
          </RouterLink>
        ))}
      </div>
    </footer>
  );
}
