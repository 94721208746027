import * as React from "react";
import { ChevronDownIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { WriteItem, statusLabels } from "@/lib/compensations/types";

import { GenericInputField } from "@/lib/types";
import { downloadSupabaseFile } from "@/lib/supabase/download-file";
import { columnToBucket } from "@/lib/supabase/buckets";

export type TableFields = Record<keyof WriteItem, GenericInputField>;

export type CTProps = {
  data: WriteItem[];
  fields: TableFields;
};

const getColumns = (fields: TableFields) =>
  [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    ...Object.entries(fields).map(([fieldName, fieldValue]) => ({
      accessorKey: fieldName,
      header: () => <div>{fieldValue.label}</div>,
      /* @ts-expect-error - fix later, row is a key of Composition */
      cell: ({ row }) => {
        const value =
          fieldValue?.input === "date" ? (
            new Date(row.getValue(fieldName)).toLocaleDateString()
          ) : fieldValue?.input === "currency" && row.getValue(fieldName) ? (
            new Intl.NumberFormat("pl-PL", {
              style: "currency",
              currency: "PLN",
            }).format(parseFloat(row.getValue(fieldName)))
          ) : fieldValue?.input === "url" && row.getValue(fieldName) ? (
            <Button
              size={"sm"}
              onClick={() =>
                downloadSupabaseFile(
                  columnToBucket[
                    (fieldName as "link_to_ticket") ||
                      "link_to_generated_document"
                  ],
                  row.getValue(fieldName)
                )
              }
            >
              Link
            </Button>
          ) : fieldValue?.input === "check" ||
            fieldValue?.input === "boolean" ? (
            row.getValue(fieldName) ? (
              "Tak"
            ) : (
              "Nie"
            )
          ) : fieldName === "status" ? (
            // @ts-expect-error - fix later, row.getValue(fieldName is an enum of Compensation["status"]
            statusLabels[row.getValue(fieldName)]
          ) : (
            row.getValue(fieldName)
          );

        return <div>{value}</div>;
      },
    })),

    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const payment = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Otwórz menu</span>
                <DotsHorizontalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {payment.link_to_generated_document && (
                <DropdownMenuItem
                  onClick={() =>
                    navigator.clipboard?.writeText(
                      payment?.link_to_generated_document ?? ""
                    )
                  }
                >
                  Skopiuj link do dokumentu
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                onClick={() =>
                  navigator.clipboard?.writeText(payment?.link_to_ticket ?? "")
                }
              >
                Skopiuj link do biletu
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Podgląd biletu</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ] as ColumnDef<WriteItem>[];

export default function ConfigurableTable(props: CTProps) {
  const { data, fields } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns = getColumns(fields);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <Card className="h-[calc(100%_-_6rem)] w-[90%] p-2 fade-in">
      <div className="flex items-center pb-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Kolumny <ChevronDownIcon className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {/* @ts-expect-error - fix later, column.id is a key of ColumnDef */}
                    {fields[column.id].label}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="h-full overflow-scroll">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Brak wyników.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}
