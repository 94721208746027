import { useEffect, useState } from "react";
import lessThan60Template from "../../templates/json/less-than-60-minutes-delay.intercity.json";
import over60Template from "../../templates/json/more-than-60-minutes-delay.intercity..json";
import { CompensationPreviewProps } from "../compensation-preview-card";
import SignaturePad from "./signature-pad";
import { useReadActiveUser } from "@/lib/user/read";
import PDFDisplay from "./pdf-display";
import { downloadSupabaseFile } from "@/lib/supabase/download-file";

export const DraftPreview = (props: CompensationPreviewProps) => {
  const { profile } = useReadActiveUser();
  const { compensation, setIsSigned } = props;
  const template = compensation?.delay_1h_or_more
    ? over60Template
    : lessThan60Template;

  const [file, setFile] = useState<string | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !file
      ? downloadSupabaseFile(
          "tickets",
          compensation.link_to_ticket,
          false
        ).then(({ url }) => {
          setFile(url);
        })
      : {};
  }, [file, compensation.link_to_ticket]);

  if (!profile) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 p-8 bg-white  mx-auto shadow-lg">
      {Object.values(template).map((section, index) => (
        <div key={index} className="m-2 flex flex-col gap-2">
          <p className="text-gray-900">
            {section.replace(/{{(.*?)}}/g, (_match, key) => {
              // if (key === "TICKET_PDF") {
              //   console.log(ticket.link_to_ticket);
              //   return file ? <iframe src={file} /> : "Trwa ładowanie...";
              // }
              // this is a calculation field, like `ticket.total / 2`
              if (key.includes("/")) {
                const [sumName, divideBy] = key.split("/");
                const sumKeyName = sumName.split(".").pop().trim();
                const x =
                  // @ts-expect-error - try to find a match
                  compensation?.[sumKeyName] || profile?.[sumKeyName];

                return (parseFloat(x) / divideBy).toFixed(2);
              } else {
                const subKey = key.split(".").pop().trim();
                return (
                  // @ts-expect-error - try to find a match
                  compensation?.[subKey] || profile?.[subKey] || key
                );
              }
            })}
          </p>
        </div>
      ))}
      <SignaturePad setIsSigned={setIsSigned} />
      <div id="ticket-preview">
        {file && compensation?.ticket_file_type?.includes("pdf") ? (
          <PDFDisplay file={file} />
        ) : (
          <img src={file as string} alt="Ticket preview" />
        )}
      </div>
    </div>
  );
};
