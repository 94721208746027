import { Database } from "../supabase/schema";

export type CompensationStatus = Database["public"]["Enums"]["ITEM_STATUS"];

export type WriteItem = Partial<
  Database["public"]["Tables"]["compensations"]["Row"]
>;

// this is what comes out of chat gpt, we use it to check validity of the input
// we later remove the `is_ticket field` and in the DB there is no such field
export type ReadItem = WriteItem & {
  is_ticket: boolean;
};

export const statusLabels: Record<CompensationStatus, string> = {
  draft: "Szkic",
  approved: "Zatwierdzony",
  signed: "Podpisany",
  payment_pending: "Oczekuje na płatność",
  payment_completed: "Płatność zakończona",
  sent: "Wysłany",
  accepted: "Zwrot zaakceptowany",
  rejected: "Zwrot odrzucony",
  payment_error: "Błąd płatności",
};

export const getBaseCardStatusColor = (status: CompensationStatus) => {
  switch (status) {
    case "draft":
    case "payment_pending":
    case "sent":
      return "bg-yellow-100 text-yellow-800";
    case "approved":
    case "signed":
    case "payment_completed":
      return "bg-green-100 text-green-800";
    case "payment_error":
    case "rejected":
      return "bg-red-100 text-red-800";
    default:
      return "bg-orange-100 text-orange-800";
  }
};
