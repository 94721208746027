import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useReadActiveUser } from "@/lib/user/read";

export default function DeleteAccount() {
  const { profile: data } = useReadActiveUser();

  const [disabled, setDisabled] = useState(true);

  return (
    <>
      <p className="w-full mb-2">Usuwanie konta</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 fade-in">
        <div className="space-y-2 flex ">
          <Label htmlFor="account-deletion">
            Klikając `Skasuj moje konto` potwierdzam, że chcę usunąć swoje konto
            i rozumiem, że ta akcja jest nieodwracalna.
          </Label>
          <Input
            id="account-deletion"
            type="checkbox"
            placeholder="Wybierz język aplikacji"
            defaultValue={data?.app_language_code || ""}
            onChange={(e) => setDisabled(!e.target.checked)}
            className="w-6 h-6"
          />
        </div>
        <div className="flex space-x-4 mt-4">
          <Button variant="outline">Anuluj</Button>
          <Button disabled={disabled} variant={"destructive"}>
            Skasuj moje konto
          </Button>
        </div>
      </div>
    </>
  );
}
