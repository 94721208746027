import { ClassAttributes, HTMLAttributes } from "react";
import { JSX } from "react/jsx-runtime";
import { ResponsiveBar } from "@nivo/bar";
import { Database } from "@/lib/supabase/schema";

const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

export function RequestedRefundsChart(
  props: JSX.IntrinsicAttributes &
    ClassAttributes<HTMLDivElement> &
    HTMLAttributes<HTMLDivElement> & {
      compensations: Database["public"]["Tables"]["compensations"]["Row"][];
    }
) {
  const compensations = props.compensations;

  const compensationRefundsPerMonth =
    compensations?.reduce(
      (acc, compensation) => {
        const month = new Date(compensation.created_at).getMonth();
        acc[month] = (acc[month] || 0) + (compensation?.requested_refund || 0);
        return acc;
      },
      {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
      } as Record<number, number>
    ) ?? {};

  return (
    <div {...props}>
      <ResponsiveBar
        data={Object.entries(compensationRefundsPerMonth).map(
          ([month, count]) => ({
            name: months[Number(month)],
            count,
          })
        )}
        keys={["count"]}
        indexBy="name"
        margin={{ top: 20, right: 0, bottom: 40, left: 40 }}
        padding={0.3}
        colors={["#16A34A"]}
        axisBottom={{
          tickSize: 0,
          tickPadding: 16,
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: 10,
          tickPadding: 16,
          tickRotation: 0,
        }}
        gridYValues={4}
        theme={{
          tooltip: {
            chip: {
              borderRadius: "9999px",
            },
            container: {
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "6px",
            },
          },
          grid: {
            line: {
              stroke: "#f3f4f6",
            },
          },
        }}
        tooltipLabel={() => `PLN`}
        enableLabel={false}
        role="application"
        ariaLabel="Wykres słupkowy przedstawiający kwoty odszkodowań w kolejnych miesiącach"
        animate={true}
        maxValue={100}
        renderWrapper={true}
      />
    </div>
  );
}
