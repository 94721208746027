import { Fragment } from "react/jsx-runtime";

const SkeletonBaseCard = () => (
  <div className="flex items-center justify-between">
    <div className="flex-1 space-y-4">
      <div className="h-5 bg-gray-300 dark:bg-gray-800 rounded" />
      <div className="h-4 bg-gray-200 max-w-[65%] dark:bg-gray-700 rounded" />
      <div className="h-5 max-w-[40%] bg-gray-200 dark:bg-gray-700 rounded" />
    </div>
    <div className="bg-gray-100 dark:bg-gray-600 text-gray-800 h-4 px-6 py-3 rounded-full" />
  </div>
);
export default function NewsSkeleton() {
  return (
    <section className="animate-pulse h-[calc(100%_-_6rem)] w-[90%] p-2 fade-in overflow-scroll">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="rounded-lg shadow-md p-6">
          <div className="space-y-4 h-7 flex-1 bg-gray-300 dark:bg-gray-800 rounded mb-4" />
          <SkeletonBaseCard />
        </div>
        <div className="rounded-lg shadow-md p-6">
          <div className="h-7 flex-1 bg-gray-300 dark:bg-gray-800 rounded mb-4 space-y-4" />
          <SkeletonBaseCard />
        </div>
      </div>
      <div className="rounded-lg shadow-md p-6 mt-6">
        <div className="h-7 max-w-[40%] flex-1 bg-gray-300 dark:bg-gray-800 rounded mb-4 space-y-4" />
        <div className="flex space-x-4 items-baseline m-6">
          {Array(3)
            .fill(0)
            .map((_item, i) => (
              <Fragment key={i}>
                <div className="flex-1 h-24 bg-gray-200 dark:bg-gray-600 rounded" />
                <div className="flex-1 h-36 bg-gray-200 dark:bg-gray-600 rounded" />
                <div className="flex-1 h-64 bg-gray-300 dark:bg-gray-700 rounded" />
                <div className="flex-1 h-48 bg-gray-200 dark:bg-gray-600 rounded" />
              </Fragment>
            ))}
        </div>
      </div>
    </section>
  );
}
