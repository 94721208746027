import { logger } from "../logger";
import { supabase } from "./supabase";

export async function getActiveUser() {
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser();

  if (error) {
    logger.error("Failed to get active user", error);
    throw new Error("Nie udało się odczytać zalogowanego uzytkownika.");
  }

  return user;
}
