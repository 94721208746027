import { fields as userFields } from "@/lib/user/fields";

const {
  allow_marketing_emails,
  allow_marketing_push_notifications,
  allow_marketing_text_messages,
} = userFields;

export const marketingFields = {
  allow_marketing_emails,
  allow_marketing_push_notifications,
  allow_marketing_text_messages,
};
