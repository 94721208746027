import { Database } from "../supabase/schema";
import { fields } from "./fields";
import { ProfileIssue } from "./types";

export function calculateIssues(
  profile: Database["public"]["Tables"]["profiles"]["Row"],
) {
  const newIssues: ProfileIssue[] = [];

  if (!profile) return newIssues;

  Object.entries(fields).some(([field, value]) => {
    const fieldValue = String(profile?.[field as keyof typeof profile]) || "";
    const hasIssues = value.validator
      ? value.validator?.(
        fieldValue,
      ) === false
      : false;

    const issueMessage = value.validationMessage ||
      "Nieprawidłowa wartość: " + field;

    if (hasIssues) {
      if (!newIssues.some((issue) => issue.field === field)) {
        newIssues.push({
          field: field as keyof Database["public"]["Tables"]["profiles"]["Row"],
          issue: issueMessage,
          fieldLabel: value.label,
          fieldValue: fieldValue,
        });
      }
    }
  });

  return newIssues;
}
