import { ChangeEvent, useRef, useState } from "react";
import { Input } from "./ui/input";

import "@tailwindcss/forms";
import { acceptedFileTypes } from "@/lib/compensations/accepted-file-types";

const ImageInputToggle = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    if (!selectedFile) {
      return;
    }
    if (selectedFile.size > 5 * 1024 * 1024) {
      setFile(null);
      event.target.value = "";
      event.target.files = null;
      setError("Plik jest zbyt duży. Maksymalny rozmiar to 5MB.");
      return;
    }
    setFile(selectedFile);
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setFileUrl(url);
    } else {
      setFileUrl(null);
    }
  };

  return (
    <div className="space-y-1">
      <div className="w-full max-w-xs">
        <Input
          type="file"
          ref={fileInputRef}
          id="file"
          name="file"
          required
          accept={acceptedFileTypes.join(",")}
          className="form-input w-full border p-2"
          onChange={handleFileChange}
        />
        {file && file.type === "application/pdf" && (
          <iframe
            src={fileUrl || ""}
            title="PDF Preview"
            className="mt-2 w-full h-64 border rounded"
          />
        )}
        {file && file.type.startsWith("image/") && (
          <img
            src={fileUrl || ""}
            alt="Preview"
            className="mt-2 w-full border rounded"
          />
        )}
        {error && <div className="text-red-600 text-sm">{error}</div>}
      </div>
    </div>
  );
};

export default ImageInputToggle;
