import { CompensationPreview } from "@/components/compensation-preview-card";

import { SkeletonTicket } from "../../components/ui/skeletons/ticket-skeleton";
import { useReadItems } from "@/lib/compensations/read";
import { useLayoutEffect, useState } from "react";

export default function Compensations() {
  const { data: compensations } = useReadItems();
  const [activeId, setActiveId] = useState<string | null>(null);

  useLayoutEffect(() => {
    // if url params contains an id, click the button with that id
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    setActiveId(id);
    if (activeId) {
      const button = document.getElementById(activeId);
      if (button) {
        button.click();
      }
    }
  }, [activeId]);

  return (
    <section className="px-4 py-8 md:px-6 md:py-12 overflow-scroll w-full">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {compensations
          ? compensations.map((c) => (
              <CompensationPreview
                key={c.id}
                compensation={c}
                active={c.id === activeId}
              />
            ))
          : Array(8)
              .fill(0)
              .map((_item, i) => <SkeletonTicket key={i} />)}
      </div>
    </section>
  );
}
