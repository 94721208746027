import { Auth } from "@supabase/auth-ui-react";
import { AuthProviders, ThemeSupa, ViewType } from "@supabase/auth-ui-shared";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { authDialog } from "@/lib/localization";
import { supabase } from "@/lib/supabase/supabase";

import { useTheme } from "../lib/theme/theme-provider";

const authConfig = {
  supabaseClient: supabase,
  providers: ["google", "facebook"] as AuthProviders[],
  showLinks: true,

  appearance: {
    theme: ThemeSupa,
    variables: {
      default: {
        colors: {
          brand: "#22c55e",
          brandAccent: "#22c55e/50",
          brandButtonText: "#22c55e",
          inputBackground: "transparent !important",
        },
      },
    },
  },
  localization: {
    variables: authDialog,
  },
  redirectTo: "/news",
};

export default function AuthDialog(props: { view?: ViewType }) {
  const { theme } = useTheme();
  const view = props?.view || "sign_in";

  const normalizedTheme =
    theme === "system"
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
      : theme;
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button size="lg">
          {view === "sign_in" ? "Odbierz odszkodowanie" : "Zacznij korzystać"}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="sm:h-auto h-full gap-0">
        <AlertDialogHeader>
          <AlertDialogTitle>Witaj, podróżny! </AlertDialogTitle>
          <AlertDialogDescription>
            Dostęp do wszystkich funkcji tylko dla zarejestrowanych
            użytkowników.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Auth {...authConfig} view={view} theme={normalizedTheme} />
        <AlertDialogFooter className="-mt-2">
          <AlertDialogCancel>Anuluj</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
