export const authDialog = {
  sign_up: {
    email_label: "Adres email",
    password_label: "Stwórz hasło",
    email_input_placeholder: "Twój adres email",
    password_input_placeholder: "Twoje hasło",
    button_label: "Zarejestruj się",
    loading_button_label: "Rejestrowanie ...",
    social_provider_text: "Kontynuuj przez {{provider}}",
    link_text: "Nie masz konta? Zarejestruj się",
    confirmation_text: "Sprawdź swój email w celu potwierdzenia rejestracji",
  },
  sign_in: {
    email_label: "Adres email",
    password_label: "Twoje hasło",
    email_input_placeholder: "Twój adres email",
    password_input_placeholder: "Twoje hasło",
    button_label: "Zaloguj się",
    loading_button_label: "Logowanie ...",
    social_provider_text: "Kontynuuj przez {{provider}}",
    link_text: "Masz juz konto? Zaloguj się",
  },
  magic_link: {
    email_input_label: "Adres email",
    email_input_placeholder: "Twój adres email",
    button_label: "Wyślij link logowania",
    loading_button_label: "Wysyłanie linka logowania ...",
    link_text: "Wyślij email z linkiem logowania",
    confirmation_text: "Sprawdź swój email w celu zalogowania",
  },
  forgotten_password: {
    email_label: "Adres email",
    password_label: "Twoje hasło",
    email_input_placeholder: "Twój adres email",
    button_label: "Wyślij instrukcje do resetu hasła",
    loading_button_label: "Wysyłanie instrukcji resetu hasła ...",
    link_text: "Zapomniałeś hasła?",
    confirmation_text: "Sprawdź swój email w celu zresetowania hasła",
  },
  update_password: {
    password_label: "Nowe hasło",
    password_input_placeholder: "Twoje nowe hasło",
    button_label: "Zaktualizuj hasło",
    loading_button_label: "Aktualizowanie hasła ...",
    confirmation_text: "Twoje hasło zostało zaktualizowane",
  },
  verify_otp: {
    email_input_label: "Adres email",
    email_input_placeholder: "Twój adres email",
    phone_input_label: "Numer telefonu",
    phone_input_placeholder: "Twój numer telefonu",
    token_input_label: "Token",
    token_input_placeholder: "Twój token",
    button_label: "Zweryfikuj",
    loading_button_label: "Logowanie ...",
  },
};
