import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useReadActiveUser } from "@/lib/user/read";
import { useUpdateActiveUser } from "@/lib/user/update";

import { notificationFields } from "./fields";

export default function Notofications() {
  const { profile: data } = useReadActiveUser();

  const { handleUpdateSubmit } = useUpdateActiveUser();

  return (
    <>
      <p className="w-full mb-2">Twoje powiadomienia aplikacji.</p>
      {data ? (
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-6 fade-in"
          onSubmit={handleUpdateSubmit(Object.keys(notificationFields))}
        >
          <div className="flex items-center space-x-2 gap-2">
            {Object.entries(notificationFields).map(([field, value]) => (
              <div key={field} className="flex items-center space-x-2">
                <Switch
                  id={field}
                  name={field}
                  defaultChecked={Boolean(data?.[field as keyof typeof data])}
                />
                <Label className="mb-0" htmlFor={field}>
                  {value.label}
                </Label>
              </div>
            ))}
          </div>
          <div className="flex space-x-4">
            <Button type="reset" variant="outline">
              Anuluj
            </Button>
            <Button type="submit">Zapisz</Button>
          </div>
        </form>
      ) : (
        "Trwa ładowanie danych..."
      )}
    </>
  );
}
