/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/Ic5bQ3X7qiN
 */
import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";

import HeaderMenu from "../menus/aside/main-drawer";
import ModeToggle from "../mode-toggle";
import { Button } from "../ui/button";
import { NavLink } from "react-router-dom";
import { useReadActiveUser } from "@/lib/user/read";

export function Header() {
  const { issues } = useReadActiveUser();

  return (
    <header className="fixed h-16 p-1 top-0 left-0 mx-auto border-b w-full bg-gradient-to-b from-[#22c55e]">
      <nav className="gap-8 flex flex-row w-full justify-evenly p-2">
        <HeaderMenu />
        <div className="flex flex-row items-center gap-2">
          <h1 className="flex items-center font-semibold mr-4">Pay4Delay</h1>
          <ModeToggle />
        </div>
        <Button className="relative ml-auto" size="icon" variant="ghost">
          <NavLink to="/news">
            <BellIcon />
            {issues?.length > 0 ? (
              <div className="absolute -top-1 -right-1 bg-red-500 text-white font-bold text-xs rounded-full h-5 w-5 flex items-center justify-center">
                {issues?.length}
              </div>
            ) : null}
          </NavLink>
        </Button>
      </nav>
    </header>
  );
}

function BellIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
      <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
    </svg>
  );
}
