import { createContext, useContext } from "react";
import { Session } from "@supabase/supabase-js";
import { useActiveSession, signOut } from "../supabase/session";

const AuthContext = createContext({ session: null } as {
  session: Session | null;
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { session } = useActiveSession();
  return (
    <AuthContext.Provider value={{ session }}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export { signOut };
