import { logger } from "../logger";
import { useUpdateCompensation } from "../supabase/crud-helpers/compensations";
// import { uploadFile } from "../supabase/upload-file";
import { getActiveUser } from "../supabase/user";
import { fileToBase64 } from "../../utils/base64";

export function useUploadSignedCompensation() {
  const { updateCompensation } = useUpdateCompensation();

  const uploadSignedCompensation = async (
    blob: Blob,
    compensationId: string
  ) => {
    // const day = new Date().toLocaleDateString("pl").replaceAll(".", "_");
    // const time = new Date().toLocaleTimeString("pl");
    const user = await getActiveUser();

    const file = new File([blob], compensationId, {
      type: "application/pdf",
      lastModified: +new Date(),
    });

    if (!user) {
      logger.error("No user found");
      throw new Error("Nie udało się znaleźć użytkownika");
    }

    // const uploadName = `${day}/${time}_${user.id}_${compensationId}.pdf`;
    // const { upload, uploadError } = await uploadFile(
    //   "compensations",
    //   uploadName,
    //   file
    // );
    // if (uploadError) {
    //   logger.error("Failed to upload file", uploadError);
    //   throw new Error("Nie udało się przesłać pliku");
    // }
    const base64File = await fileToBase64(file);

    const { error } = await updateCompensation({
      id: compensationId,
      // link_to_generated_document: upload?.path,
      status: "signed",
      temp_document_blob: base64File,
    });

    if (error) {
      logger.error("Failed to update data", error);
      throw new Error("Nie udało się zaktualizować danych");
    }
  };

  return {
    uploadSignedCompensation,
  };
}
