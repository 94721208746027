import { OpenAI } from "openai";

import { extractTicketDatafunction, systemPrompt } from "./prompts";
import { fileToBase64 } from "../../utils/base64";
import { ReadItem } from "../compensations/types";
import { handleReadTicketResponse } from "./handle-read-ticket";
import { logger } from "../logger";
import { OPEN_AI_MODEL } from "./model";

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

// TODO: move this to a Deno serverless function
export async function readImageTicket(img: File): Promise<ReadItem> {
  const base64Img = await fileToBase64(img);

  const client = new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true,
  });

  const response = await client.chat.completions.create({
    model: OPEN_AI_MODEL,
    response_format: { type: "json_object" },
    messages: [
      {
        role: "system",
        content: systemPrompt,
      },
      {
        role: "user",
        content: [
          {
            type: "text",
            text: "What’s in this image? Answer in json format.",
          },
          {
            type: "image_url",
            image_url: {
              url: base64Img,
            },
          },
        ],
      },
    ],
    tools: [
      {
        type: "function",
        function: extractTicketDatafunction,
      },
    ],
    tool_choice: {
      type: "function",
      function: {
        name: "extract_ticket_data",
      },
    },
  });

  console.log("ai: ", response.choices[0]);

  const output =
    // @ts-expect-error - we know the message is a string
    response.choices?.[0]?.message?.tool_calls[0].function.arguments;

  if (output) {
    const structuredResponse = JSON.parse(output);
    return handleReadTicketResponse(structuredResponse);
  }

  logger.error(
    "Wystąpił błąd podczas przetwarzania pliku. Spróbuj ponownie.",
  );

  return {
    is_ticket: false,
  };
}
