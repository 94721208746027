import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ArrowBigLeft } from "lucide-react";

import { RouterLink } from "@/components/routing/router-link";
import { settingsMenuLinks } from "@/components/routing/routes";
import { handleBackButtonClick } from "@/components/routing/utils";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

export default function Settings() {
  const location = useLocation();

  const isNestedRoute = settingsMenuLinks.some((route) =>
    location.pathname.includes(route.path)
  );

  return (
    <Card className="h-[calc(100%_-_6rem)] w-[90%] p-2 fade-in">
      {isNestedRoute ? null : (
        <CardTitle>
          <p className="text-xs text-inherit text-center p-2">
            Tutaj znajdziesz wszystkie opcje aplikacji.
          </p>
        </CardTitle>
      )}
      <CardContent className="overflow-scroll h-[calc(100%_-_2rem)] ">
        {isNestedRoute ? (
          <>
            <Button
              className="flex m-auto"
              variant="ghost"
              size="icon"
              onClick={handleBackButtonClick}
            >
              <ArrowBigLeft />
            </Button>
            <Outlet />
          </>
        ) : (
          <div className="grid gap-3 py-2">
            {settingsMenuLinks.map((route, i) => (
              <React.Fragment key={route.path}>
                <RouterLink route={route}>
                  <div className="flex gap-2 items-center">
                    {route.icon && <route.icon className="mr-2 h-4 w-4" />}
                    <div>{route.title}</div>
                  </div>
                </RouterLink>
                <div className="ml-8 text-xs text-gray-500">
                  {route.subtitle}
                </div>

                {i + 1 === settingsMenuLinks.length ? null : <Separator />}
              </React.Fragment>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
