import { ReadItem } from "../compensations/types";
import { logger } from "../logger";

export function handleReadTicketResponse(structuredResponse: ReadItem) {
  if (
    structuredResponse.is_ticket === false ||
    !structuredResponse.journey_details__route ||
    !structuredResponse.journey_details__departure_time ||
    !structuredResponse.journey_details__arrival_time ||
    !structuredResponse.journey_details__date ||
    !structuredResponse.journey_details__carrier ||
    !structuredResponse.journey_details__train_number ||
    !structuredResponse.ticket_details__ticket_number ||
    !structuredResponse.ticket_details__total_amount ||
    !structuredResponse.ticket_details__currency
  ) {
    logger.metrics("bad_ticket");
    logger.warn("Ticket data is bad", structuredResponse);
    return {
      is_ticket: false,
    };
  } else {
    logger.metrics("read_ticket");
    return structuredResponse;
  }
}
