import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from "@/App";
import {
  drawerMenuLinks,
  footerLinks,
  unlinkableRoutes,
} from "@/components/routing/routes";
import { ErrorPage } from "../layout/error-page";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      {footerLinks.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<route.element />}
          errorElement={<ErrorPage />}
        />
      ))}

      {drawerMenuLinks.map((group) =>
        group.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.element />}
            errorElement={<ErrorPage />}
          >
            {route.children?.map((child) => (
              <Route
                key={child.path}
                path={child.path}
                element={<child.element />}
                errorElement={<ErrorPage />}
              />
            ))}
          </Route>
        ))
      )}
      {unlinkableRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<route.element />}
          errorElement={<ErrorPage />}
        />
      ))}
    </Route>
  )
);
