import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useReadActiveUser } from "@/lib/user/read";
import { useUpdateActiveUser } from "@/lib/user/update";

import { profileFields } from "./fields";
import SkeletonProfile from "@/components/ui/skeletons/user-profile-skeleton";

import { CheckIcon } from "lucide-react";

export default function Profile() {
  const { profile, issues } = useReadActiveUser();
  const { handleUpdateSubmit } = useUpdateActiveUser();

  return (
    <>
      {profile ? (
        <>
          <p className="w-full mb-2">Twoje dane osobiste.</p>
          <form
            className="grid grid-cols-1 md:grid-cols-2 gap-6 fade-in"
            onSubmit={handleUpdateSubmit(Object.keys(profileFields))}
          >
            {Object.entries(profileFields).map(([field, value]) => {
              const hasIssue = issues?.find((i) => i.field === field);
              return (
                <div key={field} className="space-y-2">
                  <Label
                    htmlFor={field}
                    className={hasIssue ? "text-destructive font-bold" : ""}
                  >
                    {value.label}
                    {hasIssue ? (
                      <span className="ml-2 text-xs">
                        ({value.validationMessage})
                      </span>
                    ) : (
                      <CheckIcon className="ml-2 inline-flex" />
                    )}
                  </Label>
                  <Input
                    id={field}
                    name={field}
                    className={hasIssue ? "border-destructive" : ""}
                    placeholder={value.placeholder}
                    defaultValue={
                      String(profile?.[field as keyof typeof profile]) || ""
                    }
                    type={value.input}
                  />
                </div>
              );
            })}
            <div className="space-y-2">
              <Label
                htmlFor="offenced_committed"
                className={
                  profile["offences_committed"] || 0 > 0
                    ? "text-destructive font-bold"
                    : ""
                }
              >
                Ilość błędnych zgłoszeń - {profile.offences_committed}
                {profile["offences_committed"] || 0 > 0 ? (
                  <span className="ml-2 text-xs">
                    (Przekroczenie {profile.offences_allowed} zablokuje konto)
                  </span>
                ) : (
                  <CheckIcon className="ml-2 inline-flex" />
                )}
              </Label>
            </div>
            <div className="flex space-x-4 mt-4">
              <Button type="reset" variant="outline">
                Anuluj
              </Button>
              <Button type="submit">Zapisz</Button>
            </div>
          </form>
        </>
      ) : (
        <SkeletonProfile />
      )}
    </>
  );
}
