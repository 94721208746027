export const provinces = [
  "Mazowieckie",
  "Śląskie",
  "Wielkopolskie",
  "Małopolskie",
  "Dolnośląskie",
  "Łódzkie",
  "Pomorskie",
  "Lubelskie",
  "Zachodniopomorskie",
  "Świętokrzyskie",
  "Podkarpackie",
  "Warmińsko-mazurskie",
  "Kujawsko-pomorskie",
  "Podlaskie",
  "Lubuskie",
  "Opolskie",
];