import { supabase } from "../supabase/supabase";
import { WriteItem } from "./types";
import { useCallback, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { logger } from "../logger";
import { useDeleteCompensation } from "../supabase/crud-helpers/compensations";

export function useDeleteCompensationAndfiles() {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const { deleteCompensationById } = useDeleteCompensation();

  const deleteCompensation = useCallback(async (compensation: WriteItem) => {
    setLoading(true);
    if (compensation.link_to_generated_document) {
      const { error } = await supabase
        .storage
        .from("compensations")
        .remove([compensation.link_to_generated_document]);

      if (error) {
        logger.error("Failed to delete generated document", error);
        toast({
          title: "Błąd",
          description: ("Nie udało się usunąć pliku z dokumentem"),
        });
        setLoading(false);
      }
    }
    if (compensation.link_to_ticket) {
      const { error } = await supabase
        .storage
        .from("tickets")
        .remove([compensation.link_to_ticket]);
      if (error) {
        logger.error("Failed to delete ticket", error);
        toast({
          title: "Błąd",
          description: ("Nie udało się usunąć pliku z biletu"),
        });
        setLoading(false);
      }
    }
    const { error } = await deleteCompensationById(String(compensation.id));

    if (error) {
      logger.error("Failed to delete compensation", error);
      toast({
        title: "Błąd",
        description: ("Nie udało się usunąć rekordu z baz danych"),
      });
    } else {
      toast({
        title: "Sukces",
        description: ("Usunięto odszkodowanie"),
      });
      logger.metrics("delete_compensation");
    }
    setLoading(false);
  }, [deleteCompensationById, toast]);

  return {
    loading,
    deleteCompensation,
  };
}
