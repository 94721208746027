import { NavLink } from "react-router-dom";

export type RouteItem = {
  path: string;
  title: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

type RouterLinkProps = {
  route?: RouteItem;
  className?: string;
  children: React.ReactNode;
  active?: boolean;
};

export const RouterLink = (props: RouterLinkProps) => {
  return (
    <NavLink
      to={props.route?.path || ""}
      className={({ isActive, isPending }) =>
        `${props.className || ""} hover:underline ${
          isPending ? "pending" : isActive ? "text-primary" : ""
        }`
      }
    >
      {props.children}
    </NavLink>
  );
};
