const _required = [
  "is_ticket",
  // maybe this is not required?
  "ticket_details__passenger_full_name",
  "ticket_details__ticket_number",
  "ticket_details__total_amount",
  "ticket_details__currency",
  "journey_details__route",
  "journey_details__departure_time",
  "journey_details__arrival_time",
  "journey_details__date",
  "journey_details__carrier",
  "journey_details__train_number",
]

const _properties = {
  is_ticket: {
    type: "boolean",
    description: "Is the document a valid train ticket?",
  },
  ticket_details__passenger_full_name: {
    type: "string",
    description: "Full name of the passenger",
  },
  ticket_details__ticket_number: {
    type: "string",
    description: "Ticket number",
  },
  ticket_details__total_amount: {
    type: "string",
    description: "Total amount paid for the ticket",
  },
  ticket_details__currency: {
    type: "string",
    description: "Currency of the total amount, e.g. PLN, EUR, USD.",
  },
  journey_details__route: {
    type: "string",
    description: "Route of the journey",
  },
  journey_details__departure_time: {
    type: "string",
    description: "Departure time of the train, format HH:MM",
  },
  journey_details__arrival_time: {
    type: "string",
    description: "Arrival time of the train, format HH:MM",
  },
  journey_details__date: {
    type: "string",
    description: "Date of the journey, format DD.MM.YYYY",
  },
  journey_details__carrier: {
    type: "string",
    description: "Carrier of the train",
  },
  journey_details__train_number: {
    type: "string",
    description: "Train number",
  },
}

export const systemPrompt =
  "You are a helpful assistant. You will receive a train ticket, the document is written in Polish. " +
  "Read the uploaded ticket and extract relevant information. " +
  "The locale settings are set to Polish, and there may be Polish letters in the ticket " +
  "- they need to be preserved when extracted. " +
  "Important: always use json to respond to the user! Never add any other text to the response! " +
  "Note: if the file does not appear to be a valid and readable train ticket, " +
  "stop processing it and mark it in your response by setting the value of the field `is_ticket` to false," +
  "and return empty strings for all other fields.";

export const extractTicketDatafunction = {
  name: "extract_ticket_data",
  description: "Extract relevant information from the train ticket",
  strict: true,
  parameters: {
    type: "object",
    required: _required,
    properties: _properties,
    additionalProperties: false
  },
};

