import generatePdf from "react-to-pdf";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import { CompensationPreviewProps } from "../compensation-preview-card";

import { DraftPreview } from "./draft-preview";
import { useEffect, useRef, useState } from "react";
import { useUploadSignedCompensation } from "@/lib/compensations/upload-signed-compensation";
import { useToast } from "../ui/use-toast";
import { useUpdateCompensation } from "@/lib/supabase/crud-helpers/compensations";

const pdfWrapperStyles = (
  <style>
    {`
    #pdf-wrapper {
      width: 210mm;
      height: "500mm";
      font-size: 14px;
    }
    #signature-canvas {
      border: none !important;
    }
    #signature-controls {
      display: none
    }
    #signature-hint {
      display: none
    }
    #ticket-preview {
      margin-top: 18rem;
    }
  `}
  </style>
);

export function PDFCreatePreview(props: CompensationPreviewProps) {
  const { toast } = useToast();
  const targetRef = useRef<HTMLDivElement>(null);
  const [addStyles, setAddStyles] = useState(false);
  const { uploadSignedCompensation } = useUploadSignedCompensation();
  const { updateCompensation } = useUpdateCompensation();

  const [isSigned, setIsSigned] = useState(false);

  const approveAndSign = () => {
    updateCompensation({
      id: props.compensation.id,
      status: "signed",
    });
    setAddStyles(true);
  };

  const getPdf = async () => {
    if (!targetRef.current) return;
    const pdf = await generatePdf(() => targetRef.current, {
      filename: props?.compensation?.id || "draft",
    });
    const blob = pdf.output("blob");

    await uploadSignedCompensation(
      blob,
      props?.compensation?.id || "draft"
    ).catch((error) => {
      toast({
        title: "Błąd",
        description: error.message,
      });
    });

    toast({
      title: "Info",
      description: `Zapisano i pobrano szkic umowy.`,
    });
    setAddStyles(false);
  };

  useEffect(() => {
    if (addStyles) {
      getPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStyles]);

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button size="sm">
          <AlertDialogTitle>Zobacz szkic</AlertDialogTitle>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="w-full min-w-fit overflow-scroll max-h-[80%] max-w-full">
        <div ref={targetRef} id="pdf-wrapper">
          {addStyles && pdfWrapperStyles}
          <DraftPreview {...props} setIsSigned={setIsSigned} />
        </div>
        <AlertDialogFooter className="gap-2">
          <AlertDialogCancel>Zamknij</AlertDialogCancel>
          <Button
            size="sm"
            onClick={approveAndSign}
            disabled={
              props.compensation.status !== "draft" || isSigned === false
            }
          >
            Zatwierdź i zapisz
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
