import { useLocation } from "react-router-dom";
import { NotAllowed } from "./components/layout/not-allowed";
import AddNew from "./components/add-new-compensation";
import Footer from "./components/layout/footer";
import { Header } from "./components/layout/header";
import Main from "./components/layout/main";
import { useAuth } from "./lib/auth/auth-provider";
import { useReadActiveUser } from "./lib/user/read";
import Contact from "./routes/public/contact";
import Faq from "./routes/public/faq";
import Landing from "./routes/public/landing";
import Privacy from "./routes/public/privacy";
import Terms from "./routes/public/terms";
import "./app.css";
import { enableLogging } from "./lib/logger";

enableLogging();

function App() {
  const { allowInteractions } = useReadActiveUser();
  const auth = useAuth();
  const location = useLocation();

  if (location.pathname === "/terms") {
    return <Terms />;
  }

  if (location.pathname === "/privacy") {
    return <Privacy />;
  }

  if (location.pathname === "/contact") {
    return <Contact />;
  }

  if (location.pathname === "/faq") {
    return <Faq />;
  }

  if (auth?.session && allowInteractions !== false) {
    return (
      <>
        <Header />
        <Main />
        <AddNew />
        <Footer />
      </>
    );
  }

  if (allowInteractions === false && auth?.session) {
    return <NotAllowed />;
  }

  return <Landing />;
}

export default App;
