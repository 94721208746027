import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useReadActiveUser } from "@/lib/user/read";
import { useUpdateActiveUser } from "@/lib/user/update";

import { marketingFields } from "./fields";

export default function Marketing() {
  const { profile: data } = useReadActiveUser();

  const { handleUpdateSubmit } = useUpdateActiveUser();

  console.log({ data });

  return (
    <>
      <p className="w-full mb-2">Twoje zgody marketingowe.</p>
      {data ? (
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-6 fade-in"
          onSubmit={handleUpdateSubmit(Object.keys(marketingFields))}
        >
          {Object.entries(marketingFields).map(([field, value]) => (
            <div key={field} className="flex items-center space-x-2">
              <Switch
                id={field}
                name={field}
                defaultChecked={Boolean(data?.[field as keyof typeof data])}
              />
              <Label className="mb-0" htmlFor={field}>
                {value.label}
              </Label>
            </div>
          ))}
          <div className="flex space-x-4">
            <Button type="reset" variant="outline">
              Anuluj
            </Button>
            <Button type="submit">Zapisz</Button>
          </div>
        </form>
      ) : (
        "Trwa ładowanie danych..."
      )}
    </>
  );
}
