/* eslint-disable @typescript-eslint/no-explicit-any */

import { metricsEvents } from "./metrics";
import {
  enableSentryLogging,
  logSentryError,
  logSentryMessage,
  logSentryMetric,
} from "./sentry";

export function enableLogging() {
  enableSentryLogging();
}

export const logger = {
  error: (message?: any, ...optionalParams: any[]) => {
    console.error(message, ...optionalParams);
    logSentryError(message, optionalParams);
  },
  info: (message?: any, ...optionalParams: any[]) => {
    console.info(message, ...optionalParams);
    logSentryMessage(message, { ...optionalParams }, "info");
  },
  warn: (message?: any, ...optionalParams: any[]) => {
    console.warn(message, ...optionalParams);
    logSentryMessage(message, { ...optionalParams }, "warning");
  },
  log: (message?: any, ...optionalParams: any[]) => {
    console.log(message, ...optionalParams);
    logSentryMessage(message, { ...optionalParams }, "log");
  },
  debug: (message?: any, ...optionalParams: any[]) => {
    console.debug(message, ...optionalParams);
    logSentryMessage(message, { ...optionalParams }, "debug");
  },
  metrics: (
    message: keyof typeof metricsEvents,
  ) => {
    console.info(message);
    logSentryMetric(message);
  },
};
