export default function Help() {
  return (
    <div className="flex h-full flex-col m-24 text-center">
      <p className="text-current font-bold text-lg">
        Jeśli potrzebujesz pomocy, skontaktuj się z nami pod adresem{" "}
        <a
          href="mailto:hello@pay4delay.app?subject=PAY4DELAY: Prośba o pomoc"
          className="underline text-primary"
        >
          hello@pay4delay.app
        </a>
      </p>
      <br />
      <p className="text-current font-bold text-lg">
        Jeśli chcesz zaproponować nową funkcjonalność, opisz ją tutaj{" "}
        <a
          href="https://insigh.to/b/pay4delay"
          target="_blank"
          rel="noreferrer"
          className="underline text-primary"
        >
          https://insigh.to/b/pay4delay
        </a>
      </p>
    </div>
  );
}

/*
        <a
              href="https://insigh.to/b/pay4delay"
              target="_blank"
              rel="noreferrer"
            >
              */
