import { useLocation } from "react-router-dom";
import { Plus } from "lucide-react";

import MinimalCompensationFormContent from "./forms/minimal-compensation-form-content";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./ui/drawer";
import { Tabs, TabsContent } from "./ui/tabs";

import CompensationLoader from "./ui/skeletons/compensation-loader";
import { useCreateCompensationItem } from "@/lib/compensations/create";

export default function AddNewItem() {
  const { createCompensation, loading } = useCreateCompensationItem();
  const location = useLocation();

  const show =
    location.pathname === "/compensations" ||
    location.pathname === "/tickets" ||
    location.pathname === "/news";

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center fixed inset-0 bg-background bg-opacity-100 z-50">
          <CompensationLoader />
        </div>
      ) : (
        <Drawer>
          <DrawerTrigger asChild>
            <Button
              className={`transition-all fixed right-0 -translate-y-36 opacity-100 h-16 aspect-square border border-white z-50 hover:scale-105 ${
                show
                  ? "-translate-x-8 opacity-100 scale-100"
                  : "translate-x-0 opacity-0 scale-0"
              }`}
            >
              <Plus size={48} />
            </Button>
          </DrawerTrigger>
          <DrawerContent>
            <form
              className="mx-auto w-full max-w-sm"
              onSubmit={createCompensation}
            >
              <DrawerHeader>
                <DrawerTitle>Nowe odszkodowanie</DrawerTitle>
                <DrawerDescription>
                  Dodaj PDF lub zdjęcie biletu.
                </DrawerDescription>
              </DrawerHeader>
              <div className="p-4 pb-0">
                <Tabs defaultValue="minimal">
                  <TabsContent value="minimal">
                    <Card className="max-h-64 overflow-scroll">
                      <CardHeader>
                        <CardTitle>Bilet</CardTitle>
                        <CardDescription>
                          Dodaj bilet w formie pliku pdf lub zdjęcia, a my
                          zajmiemy się resztą.
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="space-y-2">
                        <MinimalCompensationFormContent />
                      </CardContent>
                    </Card>
                  </TabsContent>
                </Tabs>
              </div>
              <DrawerFooter>
                <Button type="submit" className="rounded-lg">
                  Dodaj
                </Button>
                <DrawerClose asChild>
                  <Button variant="outline" id="cancel-add">
                    Anuluj
                  </Button>
                </DrawerClose>
              </DrawerFooter>
            </form>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}
