import { useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { logger } from "../logger";
import { useReadCompensations } from "../supabase/crud-helpers/compensations";

export function useReadItems() {
  const { data, error } = useReadCompensations();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      logger.error("useReadItems", { error });
      toast({
        title: "Błąd",
        description: "Nie udało się pobrać danych.",
      });
    }
  }, [toast, error]);

  return { data, error };
}
