import { CompensationStatus, statusLabels } from "@/lib/compensations/types";

import { PDFCreatePreview } from "./pdf/pdf-create-modal";
import { PDFPreview } from "./pdf/pdf-preview-modal";
import { Database } from "@/lib/supabase/schema";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Button } from "./ui/button";
import { useDeleteCompensationAndfiles } from "@/lib/compensations/delete";
// import { useUpdateCompensation } from "@/lib/supabase/crud-helpers/compensations";
import { downloadSupabaseFile } from "@/lib/supabase/download-file";

export type CompensationPreviewProps = {
  compensation: Partial<Database["public"]["Tables"]["compensations"]["Row"]>;
  active?: boolean;
  setIsSigned?: (isSigned: boolean) => void;
};

export const CompensationPreview = (props: CompensationPreviewProps) => {
  const stopsArray = props?.compensation?.journey_details__route?.split("-");
  const { deleteCompensation } = useDeleteCompensationAndfiles();
  // const { updateCompensation } = useUpdateCompensation();

  const __delete = () => deleteCompensation(props.compensation);

  // const __updateToApproved = () =>
  //   updateCompensation({
  //     id: props.compensation.id,
  //     status: "approved",
  //   });

  const __download = () =>
    downloadSupabaseFile(
      "compensations",
      props.compensation.link_to_generated_document
    );

  return (
    <div
      className={
        "rounded-lg w-full border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-950 " +
        (props?.active ? "border-primary border-2 " : " ")
      }
    >
      <div className="mb-2 flex items-center justify-between">
        <p className="text-sm line-clamp-1 font-medium text-gray-500 dark:text-gray-400">
          {stopsArray
            ? stopsArray[0] + " - " + stopsArray[stopsArray.length - 1]
            : "..."}
        </p>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
          {props.compensation.journey_details__date || "..."}
        </p>
      </div>
      <p className="mb-4 text-lg font-bold">
        {props?.compensation?.delay_1h_or_more ? "Ponad 59 " : "Mniej niz 59"}{" "}
        minut opóźnienia
      </p>
      <div className="flex gap-2 flex-col">
        <PDFPreview
          ctaLabel="Zobacz bilet"
          pdfUrl={props.compensation.link_to_ticket}
        />
        {props.compensation?.link_to_generated_document ? (
          <PDFPreview
            ctaLabel="Zobacz zgłoszenie"
            pdfUrl={props.compensation?.link_to_generated_document}
            bucket="compensations"
          />
        ) : null}
        {props.compensation?.temp_document_blob ? (
          <PDFPreview
            ctaLabel="Zobacz zgłoszenie"
            blob={props.compensation.temp_document_blob}
          />
        ) : null}
        {!props.compensation?.link_to_generated_document &&
        !props.compensation?.temp_document_blob ? (
          <PDFCreatePreview compensation={props.compensation} />
        ) : null}
        <div className="flex gap-2 items-center justify-between">
          <div>
            <strong>STATUS: </strong>
            {
              statusLabels[props.compensation?.status as CompensationStatus]
            }{" "}
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default" size="sm">
                Akcje
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="  ">
              <DropdownMenuItem
                onClick={__delete}
                disabled={
                  ["draft", "approved", "signed"].includes(
                    String(props.compensation.status)
                  ) === false
                }
              >
                Usuń
              </DropdownMenuItem>
              {/* <DropdownMenuItem
                onClick={__updateToApproved}
                disabled={props.compensation.status !== "draft"}
              >
                Zatwierdź
              </DropdownMenuItem> */}
              <DropdownMenuItem
                onClick={__download}
                disabled={props.compensation.link_to_generated_document == null}
              >
                Pobierz zgłoszenie
              </DropdownMenuItem>
              <DropdownMenuItem disabled>Opłać</DropdownMenuItem>
              <DropdownMenuItem disabled>Wyślij</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {props?.compensation?.created_at ? (
          <span className="text-xs">
            Utworzono{" "}
            {new Date(props.compensation.created_at).toLocaleDateString("pl", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            o godz.{" "}
            {new Date(props.compensation.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        ) : null}
        <details>
          <summary>Debug</summary>
          <pre>{JSON.stringify(props.compensation, null, 2)}</pre>
        </details>
      </div>
    </div>
  );
};
